import React from 'react';
import { Typography } from 'antd';
import theme from './theme';
import { useIntl } from 'gatsby-plugin-intl';
import { useMediaQuery } from 'react-responsive';

const { Paragraph } = Typography;

const ShowMore = ({ children, lines, smallDeviceLines }) => {
  const intl = useIntl();
  const isWiderScreen = useMediaQuery({ query: theme.breakpoints.md });

  return (
    <Paragraph
      ellipsis={{
        rows: isWiderScreen ? lines || 6 : smallDeviceLines || 12,
        expandable: true,
        symbol: <span className="more-link">{intl.formatMessage({ id: 'more' })}</span>,
      }}
    >
      {children}
    </Paragraph>
  );
};

export default ShowMore;
